import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { UserClassEnum } from '../models/users/user.model';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import View from 'ol/View';
import XYZ from 'ol/source/XYZ';
import OSM from 'ol/source/OSM';

const APP_NAME = 'farm-mon';

declare var vars;

export type OpenLayerType = 'vworld' | 'osm';
export interface SiteConfig {
    code: string;
    name?: string;
    appName?: string;
    logo?: string;
    logoBig?: string;
    icon?: string;
    openLayerType?: OpenLayerType;
    openLayerCache?: string;
}

const defaultSiteConfig = {
    code: 'fc',
    openLayerType: 'vworld' as OpenLayerType,
    openLayerCache: ''
}

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  public loginBackPath: string;
  public siteConfig$ = new BehaviorSubject<SiteConfig>(defaultSiteConfig);

  constructor(
    public _location: Location,
    public router: Router,
    public http: HttpClient,
    ) {
      this.getSiteConfig();
    }

  private deriveQualifiedKey(key: string): string {
    return APP_NAME + key;
  }

  setItem(key: string, value: Object) {
    // console.log('addItem(), key=', key, value);
    return localStorage.setItem(this.deriveQualifiedKey(key), JSON.stringify(value));
  }

  removeItem(key: string) {
    // console.log('removeItem(), key=', key);
    return localStorage.removeItem(this.deriveQualifiedKey(key));
  }

  getItem(key: string) {
    const ret = localStorage.getItem(this.deriveQualifiedKey(key));
    return JSON.parse(ret);
  }

  public get tokenInfo() {
    return this.getItem('token_info');
  }

  public set tokenInfo(value) {
    this.setItem('token_info', value);
  }

  public get userClass(): string {
    return this.getItem('userClass') || UserClassEnum.admin;
  }

  public set userClass(value: string) {
    this.setItem('userClass', value);
  }

  // public get isAdmin(): boolean {
  //   if (this.userClass === UserClassEnum.member) {
  //     return false;
  //   }
  //   return true;
  // }

  public get rememberMe(): boolean {
    return this.getItem('rememberMe');
  }

  public set rememberMe(value: boolean) {
    this.setItem('rememberMe', value);
  }

  public get rememberAuthId(): string {
    return this.getItem('rememberAuthId');
  }

  public set rememberAuthId(value: string) {
    this.setItem('rememberAuthId', value);
  }

  public get rememberEmail(): string {
    return this.getItem('rememberEmail');
  }

  public set rememberEmail(value: string) {
    this.setItem('rememberEmail', value);
  }

  public get userEmail(): string {
    return this.getItem('userEmail');
  }

  public set userEmail(value: string) {
    this.setItem('userEmail', value);
  }

  public get serverUrl() {
    return 'connfarm';
  }

  public get masterKey() {
    return 'cdruo5anfnz8r9fm71t8by';
  }

  public get clientKey() {
    return this.project + '-admin';
  }

  public get apiKey() {
    return 'ocwSonenybfZaFbr97m';
  }

  public get project() {
    return 'connfarm';
  }

  public getSiteConfig() {
    this.http.post<SiteConfig>("/connfarm/amon/cmd.json", { "command": "site_config" }).subscribe(res => {
      this.siteConfig$.next(res);
      vars.openLayerType = res.openLayerType;
      vars.openLayerCache = res.openLayerCache;
    });
  }
}
