import { DOCUMENT } from '@angular/common';
import { AfterContentChecked, Component, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ConfigService } from './service/config.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styles: []
})
export class AppComponent implements AfterContentChecked {
  title = 'farm-web';
  faviconFileName: string;

  constructor(
    private titleService: Title,
    @Inject(DOCUMENT) private doc: Document,
    private configService: ConfigService
  ) {}

  ngAfterContentChecked(): void {
    this.configService.siteConfig$.subscribe(siteConfig => {
      this.setSiteVariables(siteConfig.code);
      this.titleService.setTitle(this.title);
      this.doc.getElementById('appFavicon').setAttribute('href', '/assets/images/icon/' + this.faviconFileName);
    })
  }

  setSiteVariables(code: string) {
    switch(code) {
      case 'gj':
        this.title = "광주광역시 농업기술센터";
        this.faviconFileName = "gjfarm_logo.png";
        break;
      default:
        this.title = "Azerbaijan Farm";
        this.faviconFileName = "az-ministry_icon.png";
    }
  }
}
