import * as _ from 'lodash';
import * as moment from 'moment';

export class BaseModel {

  public uiVariable: any = {};

  private _attributes: any = {};
  private _changed: any = {};
  private _origin: any = {};

  public debug = false;

  constructor(attr: any = null) {
    this.setAttrs(attr);
  }

  public setAttrs(attrs: any, withOrigin = true, withRevert = true) {
    this._attributes = attrs || {};
    if (withOrigin) {
      this._origin = _.clone(this._attributes);
    }
    if (withRevert) {
      this._changed = {};
    }
  }

  public setOrgAttr(key: string, value: any) {
    this._origin[key] = value;
    this.set(key, value, false);
    delete this._changed[key];
  }

  public get(attr: string): any {
    return this._attributes[attr];
  }

  public setData(data: any, withChanged = true) {
    this.set(data.itemId, data.value, withChanged);
  }

  public set(key: string, value: any, withChanged = true) {
    // console.log('setChange(), key=', key, value);
    this._attributes[key] = value;
    if (withChanged) {
      this.setChange(key, value);
    }
  }

  public unset(key: string, withChanged = true) {
    delete this._attributes[key];
    if (withChanged) {
      delete this._changed[key];
    }
  }


  public setChange(key: string, value: any) {
    // console.log('setChange(), key=', key, value);
    if (this.isEqual(key)) {
      delete this._changed[key];
    } else {
      this._changed[key] = value;
    }
  }

  public isEqual(key: string): boolean {
    const cur = this._attributes[key];
    const org = this._origin[key];

    if (_.isEqual(cur, org)) {
      // console.log('isEqual(), key=', key, cur, org);
      return true;
    }
    // console.log('isEqual(), key=', key, cur, org);
    if (_.isNil(org)) {
      if (_.isString(cur) && cur.length === 0) {
        // console.log('isEqual(), cur.length=', cur.length);
        return true;
      }
      // console.log('isEqual(), typeof cur=', typeof cur);
      // console.log('isEqual(), typeof org=', typeof org);
    }
    return false;
  }

  public get isChanged(): boolean {
    return this.hasChanged();
  }

  public get applicable(): boolean {
    return this.isChanged;
  }

  public hasChanged(key = null): boolean {
    if (key) {
      if (this._changed[key]) {
        return true;
      }
      return false;
    }
    // console.log('hasChanged(), this._changed=', this._changed);
    if (_.keys(this._changed).length > 0) {
      return true;
    }
    return false;
  }

  public getChanged(): any {
    return this._changed;
  }

  public getAttrs(): any {
    return this._attributes;
  }

  public revert() {
    this._changed = {};
    this._attributes = _.clone(this._origin);
  }

  public get objectId(): string {
    return this.get('objectId');
  }

  public set objectId(value: string) {
    this.set('objectId', value);
  }

  public get createdAt(): string {
    return this.get('createdAt');
  }

  public get createAtDate(): any {
    return new Date(this.createdAt);
  }

  public get updatedAt(): any {
    return this.get('updatedAt');
  }

  public get updatedDate(): any {
    const md = moment(this.updatedAt);
    // console.log('updatedDate(), md=', md);
    // HTMLFormControlsCollection.
    return md.toDate();
    // return new Date(this.updatedAt);
    // return this.updatedAt;
  }

  public get apiErrMag(): string {
    return this.get('apiErrMag');
  }

  public set apiErrMag(value: string) {
    this.set('apiErrMag', value);
  }

} // end of class

export function Attr(target: Object, name: string) {
  // console.log('======================');
  // console.log('target=', target);
  Object.defineProperty(target, name, {
      get: function () { return this.get(name); },
      set: function (value) { this.set(name, value); },
      enumerable: true,
      configurable: true
  });
}
