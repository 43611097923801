import { BaseModel, Attr } from './../base/base-model';
import UserRoleUtil from './user-role.util';
import DateUtil from 'src/app/utils/date-util';
import { UserFarmModel } from '../farm/user-farm.model';
import { NotiItemModel } from './noti-item.model';

export class UserSettingsModel extends BaseModel {

  public static readonly ModelName = 'UserSettingsModel';

  // public static readonly UserRole = {
  //   SUPER_ADMIN: 'SUPER_ADMIN',
  //   ADMIN: 'ADMIN',
  //   USER: 'USER',
  //   PAID_USER: 'PAID_USER',
  //   AGRICULTURAL_CENTER: 'AGRICULTURAL_CENTER',
  //   MENTOR: 'MENTOR',
  //   BUSINESS_PARTNER: 'BUSINESS_PARTNER',
  // };

  @Attr public settingsId: number;
  @Attr public notiSettings: string;

  notiItems: NotiItemModel[] = [];  

  constructor(public data: any = null) {
    super();
    if (data) {
      this.setAttrs(data);
      if (this.notiSettings) {
        this.buildNotiItems();
      }
    }
  }

  buildNotiItems() {
    const ftag = `buildNotiItems(),`;
    try {
      const noti = JSON.parse(this.notiSettings);
      // const keys = Object.keys(noti);
      this.notiItems = [];
      const keys = Object.keys(NotiItemModel.NotiClass);      
      for (const key of keys) {
        this.notiItems.push(new NotiItemModel(noti[key]));
        // console.log(key, noti[key]);
      }
      // console.log(keys);
    } catch (err) {
      console.log(ftag, 'err=', err);
    }
  }

  pack() {
    const data = {};
    for (const item of this.notiItems) {
      data[item.notiClass] = item.getAttrs();
    }
    this.notiSettings = JSON.stringify(data);
  }
}
