import { BaseModel } from '../models/base/base-model';
import * as _ from 'lodash';
import * as momentTz from 'moment-timezone';

export default class DateUtil {

    static readonly DefaultTz = 'Asia/Seoul';

    static getPreviousDay(dayCnt: number): momentTz.Moment {
        return momentTz.tz(new Date(), DateUtil.DefaultTz).subtract(dayCnt, 'days');
    }

    static getNextDay(dayCnt: number, date = new Date()): momentTz.Moment {
        return momentTz.tz(date, DateUtil.DefaultTz).add(dayCnt, 'days');
    }


    public static makeDate(data: any): Date {
        if (!data) {
            return null;
        }
        return new Date(data.year, data.monthValue - 1, data.dayOfMonth, data.hour, data.minute, data.second);
    }

    static getMoment(date = new Date()): momentTz.Moment {
        return momentTz.tz(date, DateUtil.DefaultTz);
    }

    static isSameDay(d1: Date, d2: Date): boolean {
        const d11 = DateUtil.getMoment(d1);
        const d22 = DateUtil.getMoment(d2);
        const n1 = Number(d11.format('YYYYMMDD'));
        const n2 = Number(d22.format('YYYYMMDD'));
        if (n1 === n2) {
            return true;
        }
        return false;
    }

    static getDayKey(date = new Date()): number {
        return Number(DateUtil.getMoment(date).format('YYYYMMDD'));
    }

    static getMonthKey(date = new Date()): string {
        return DateUtil.getMoment(date).format('YYYYMM');
    }

    static isToday(date: Date): boolean {
        return DateUtil.isSameDay(new Date(), date);
    }

    static getYesterday(date = new Date()): momentTz.Moment {
        return momentTz.tz(date, DateUtil.DefaultTz).subtract(1, 'days');
    }

    static getTomorrow(date = new Date()): momentTz.Moment {
        return momentTz.tz(date, DateUtil.DefaultTz).add(1, 'days');
    }

    static getDayDate(date = new Date()): Date {
        const p = this.getDateParams(date);
        return new Date(p.year, p.month - 1, p.day);
    }

    static getDateParams(date = new Date()): any {
        const m = momentTz.tz(date, DateUtil.DefaultTz);
        return {
            year: Number(m.format('YYYY')),
            month: Number(m.format('MM')),
            day: Number(m.format('DD')),
            dayKey: Number(`${m.format('YYYY')}${m.format('MM')}${m.format('DD')}`),
            monthKey: Number(`${m.format('YYYY')}${m.format('MM')}`),
        };
    }

    static isAm(date = new Date()): boolean {
        const m = DateUtil.getMoment(date);
        if (m.hour() >= 12) {
            return false;
        }
        return true;
    }

    static diffDay(date1: Date, date2: Date): number {
        return DateUtil.getMoment(date1).diff(DateUtil.getMoment(date2), 'days');
    }

    static dayKey2Date(dayKey: number): Date {
        const dk = `${dayKey}`;
        const yy = Number(dk.substring(0, 4));
        const mm = Number(dk.substring(5, 6));
        const dd = Number(dk.substring(6, 8));
        return new Date(yy, mm - 1, dd);
    }

    static getMonday(date = new Date()): Date {
        const d = DateUtil.getMoment(date).toDate();
        const day = d.getDay();
        const diff = d.getDate() - day + (day === 0 ? -6 : 1);
        const mond = new Date(d.setDate(diff));
        return DateUtil.getDayDate(mond);
    }

    static getWeekEnd(date = new Date()): Date {
        const ftag = `getWeekEnd(${date}),`;
        const dd = DateUtil.getMoment(date).add(1, 'week').add(-1, 'seconds').toDate();
        // console.log(ftag, 'dd=', dd);
        return dd;
    }

    static getWeekOfMonth(date: Date): number {
        const ftag = `getWeekOfMonth(${date}),`;
        var firstWeekday = new Date(date.getFullYear(), date.getMonth(), 1).getDay();
        var offsetDate = date.getDate() + firstWeekday - 1;
        return Math.floor(offsetDate / 7);

        // const adjustedDate = date.getDate() + date.getDay();
        // console.log(ftag, 'adjustedDate=', adjustedDate, date.getDate(), date.getDay());
        // const prefixes = ['0', '1', '2', '3', '4', '5'];
        // const ret =  (parseInt(prefixes[0 | adjustedDate / 7]) + 1);
        // console.log(ftag, 'ret=', ret, DateUtil.getDayKey(date));
        // return ret;
    }

} // end of class
