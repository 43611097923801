import { BaseModel, Attr } from './../base/base-model';
import UserRoleUtil from './user-role.util';
import DateUtil from 'src/app/utils/date-util';
import { UserFarmModel } from '../farm/user-farm.model';

export class NotiItemModel extends BaseModel {

  public static readonly ModelName = 'NotiItemModel';

  public static readonly NotiClass = {
    highTemp: 'highTemp',
    lowTemp: 'lowTemp',
    ventStartTime: 'ventStartTime',
    wateringStartTime: 'wateringStartTime',
    seriousTranspiration: 'seriousTranspiration',
    veryManyTranspiration: 'veryManyTranspiration',
    fitTranspiration: 'fitTranspiration',
    mentoring: 'mentoring',
  };

  @Attr public categoryType: string;
  @Attr public mainPageDisplay: boolean;
  @Attr public notiClass: string;
  @Attr public push: boolean;

  constructor(public data: any = null) {
    super();
    if (data) {
      this.setAttrs(data);
    }
  }

  get name(): string {
    switch(this.notiClass) {
      case NotiItemModel.NotiClass.highTemp: return '최고 온도 경보';
      case NotiItemModel.NotiClass.lowTemp: return '최저 온도 경보';
      case NotiItemModel.NotiClass.ventStartTime: return '환기 시작 시간';
      case NotiItemModel.NotiClass.wateringStartTime: return '관수 시작 시간';
      case NotiItemModel.NotiClass.seriousTranspiration: return '심각한 증산 알림';
      case NotiItemModel.NotiClass.veryManyTranspiration: return '매우 많은 증산 알림';
      case NotiItemModel.NotiClass.fitTranspiration: return '적합 알림';
      case NotiItemModel.NotiClass.mentoring: return '멘토링 메시지';
    }
    return this.notiClass;
  }
 

  // HIGH_TEMP((short) 1, CategoryType.TEMP, true, "[{farm}] 현재 농장 내부 : 온도 {temp}°C, 최고온도({maxTemp}°C)이상", true),
  // LOW_TEMP((short) 2, CategoryType.TEMP, true, "[{farm}] 현재 농장 내부 : 온도 {temp}°C, 최저온도({minTemp}°C)이하", true),
  // VENT_START_TIME((short) 3, null, true, "현재 외부온도 {extTemp}°C, 환기 시작 권장, 환기실제시간 입력 바람"),
  // WATERING_START_TIME((short) 4, null, true, "현재 농장 내부 온도 {temp}°C, 습도 {humidity}%, 관수 시작 권장"),
  // SERIOUS_TRANSPIRATION((short) 5, CategoryType.TRANSPIRATION, true, "[{farm}] 현재 농장 내부온도 {temp}°C, 습도 {humidity}%, HD {hd}g/㎥, 심각한 증산", true),
  // VERY_MANY_TRANSPIRATION((short) 6, CategoryType.TRANSPIRATION, false, "[{farm}] 현재 농장 내부온도 {temp}°C, 습도 {humidity}%, HD {hd}g/㎥, 매우 많은 증산", true),
  // FIT_TRANSPIRATION((short) 7, CategoryType.TRANSPIRATION, false, "[{farm}] 현재 농장 내부온도 {temp}°C, 습도 {humidity}%, HD {hd}g/㎥, 적합"),
  // MENTORING((short) 8, null, true, "{mentorName} 멘토의 메시지가 왔습니다");
}
