import { UserModel } from './user.model';

export default class UserRoleUtil {

    static getRole(model: UserModel): string {
        const roles = model.roles || [];
        let role = this.findRole(UserModel.UserRole.SUPER_ADMIN, roles);
        if (role) {
            return role;
        }
        role = this.findRole(UserModel.UserRole.ADMIN, roles);
        if (role) {
            return role;
        }
        // role = this.findRole(UserModel.UserRole.AGRICULTURAL_CENTER, roles);
        // if (role) {
        //     return role;
        // }
        // role = this.findRole(UserModel.UserRole.BUSINESS_PARTNER, roles);
        // if (role) {
        //     return role;
        // }
        role = this.findRole(UserModel.UserRole.MENTOR, roles);
        if (role) {
            return role;
        }
        role = this.findRole(UserModel.UserRole.PAID_USER, roles);
        if (role) {
            return role;
        }
        role = this.findRole(UserModel.UserRole.USER, roles);
        if (role) {
            return role;
        }
        return UserModel.UserRole.USER;
    }

    static findRole(role: string, roles: string[]): string {
        for (const key of roles) {
            if (role === key) {
                return key;
            }
        }
        return null;
    }

    static getRoleName(role: string): string {
        switch(role) {
            case UserModel.UserRole.SUPER_ADMIN: return '최고관리자';
            case UserModel.UserRole.ADMIN: return '관리자';
            case UserModel.UserRole.USER: return '무료회원';
            case UserModel.UserRole.PAID_USER: return '유료회원';
            // case UserModel.UserRole.AGRICULTURAL_CENTER: return '농업센터';
            case UserModel.UserRole.MENTOR: return '멘토';
            // case UserModel.UserRole.BUSINESS_PARTNER: return '협력사';
        }
        return role;
    }

}