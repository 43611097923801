import { BaseModel, Attr } from './../base/base-model';
import UserRoleUtil from './user-role.util';
import DateUtil from 'src/app/utils/date-util';
import { UserFarmModel } from '../farm/user-farm.model';
import { UserSettingsModel } from './user-settings.model';
import { SharedFarmModel } from '../farm/shared-farm.model';

export enum UserClassEnum {
  member = 'member',
  admin = 'admin',
  system = 'system',
}

export class UserModel extends BaseModel {

  public static readonly ModelName = 'UserModel';

  public static readonly UserRole = {
    SUPER_ADMIN: 'SUPER_ADMIN',
    ADMIN: 'ADMIN',
    USER: 'USER',
    PAID_USER: 'PAID_USER',
    // AGRICULTURAL_CENTER: 'AGRICULTURAL_CENTER',
    MENTOR: 'MENTOR',
    // BUSINESS_PARTNER: 'BUSINESS_PARTNER',
  };

  public static readonly AuthType = {
    LOCAL: 'LOCAL',
    KAKAO: 'KAKAO',
    NAVER: 'NAVER',
    GOOGLE: 'GOOGLE',
  };

  userFarmModels: UserFarmModel[] = [];

  public passwordConfirm: string;
  public phoneItems = [null, null, null];
  public fdErrors: any = {};

  @Attr public email: string;
  @Attr public appMember: boolean;
  @Attr public nickname: string;
  @Attr public authProviderType: string;
  @Attr public password: string;
  @Attr public name: string;
  @Attr public phone: string;

  @Attr public authId: string;
  @Attr public userType: string;
  @Attr public userId: string;
  @Attr public idx: number;
  @Attr public regionArea: string;
  @Attr public title: string;
  @Attr public createDt: any;
  @Attr public matchingLimit: number;

  @Attr public accountId: string;
  @Attr public installId: string;
  @Attr public verifyId: string;
  @Attr public profileId: string;
  @Attr public role: string;
  @Attr public roles: string[];

  @Attr public imageUrl: string;
  @Attr public userSettings: string;


  @Attr public serviceEnabled: boolean;

  public requiredAttrs: Array<string> = [];
  userSettingsModel: UserSettingsModel;

  userFileSrc;
  SharedFarmsPut: SharedFarmModel[] = [];
  SharedFarmsGet: SharedFarmModel[] = [];

  constructor(public data: any = null) {
    super();
    if (data) {
      this.setAttrs(data);
      this.idx = this.idx || data.userIdx;
      if (this.userSettings) {
        this.userSettingsModel = new UserSettingsModel(this.userSettings);
      }
    }
  }

  get isMentoUser(): boolean {
    if (this.role === UserModel.UserRole.MENTOR) {
      return true;
    }
    return false;
  }

  // public getLabelName(key) {
  //   switch (key) {  //     
  //     case 'email': return 'Email';
  //     case 'passwd': return 'Password';
  //     case 'name': return 'Name';
  //     case 'phoneNumber': return 'Phone Number';
  //     case 'passwdConfirm': return 'Password Confirm';
  //   }
  //   return 'unknown key';
  // }

  public getMaxLen(key) {
    switch (key) {
      case 'email': return 128;
      case 'passwd': return 30;
      case 'name': return 30;
      case 'phoneNumber': return 16;
      case 'passwdConfirm': return 30;
    }
    return 0;
  }

  public get roleName(): string {
    return UserRoleUtil.getRoleName(this.role);
  }

  public get userClass(): string {
    this.roles = this.roles || [];
    if (this.roles.length === 0) {
      return null;
    }
    return this.roles[0];
  }

  public set userClass(value: string) {
    this.roles = this.roles || [];
    if (this.roles.length === 0) {
      this.roles.push(value);
    } else {
      this.roles[0] = value;
    }
  }

  public get createDate(): Date {
    return DateUtil.makeDate(this.createDt);
  }

  static getKeyName(key: string): string {
    switch (key) {
      case 'idx': return '번호';
      case 'role': return '사용자 유형';
      case 'name': return '이름';
      case 'password': return '비밀번호';
      case 'passwordConfirm': return '비밀번호 확인';
      case 'phone': return '전화번호';
      case 'imageUrl': return '프로필 사진';
      case 'userId': return 'ID(E-mail)';
      case 'maxFarmCount': return '멘티농장 최대 수';
      case 'etc': return '기타';
      case 'desc': return '설명';
      case 'userClass': return '회원 구분';
      case 'farmInfo': return '농장 정보';
      // case 'createDt': return '등록일';
      case 'createDt': return '가입일자';
      case 'regionArea': return '소속(지역)';
    }
    return key;
  }

  getUserFilePath(fileClass: string): string {
    return `media/file?userId=${this.userId}&fileClass=${fileClass}`;
  }

  checkAppMember(): boolean {
    let ret = false;
    switch (this.role) {
      case UserModel.UserRole.MENTOR:
      case UserModel.UserRole.PAID_USER:
      case UserModel.UserRole.USER: {
        ret = true;
      }
    }
    this.appMember = ret;
    return ret;
  }

  get farmNameList(): string[] {
    const ret = [];
    for (const uf of this.userFarmModels) {
      if (uf.farmModel) {
        ret.push(uf.farmModel.name);
      }
    }
    return ret;
  }

  static GetAuthTypeName(authProviderType: string): string {
    switch (authProviderType) {
      case UserModel.AuthType.LOCAL: 'email';
      case UserModel.AuthType.KAKAO: 'kakao';
      case UserModel.AuthType.NAVER: 'naver';
      case UserModel.AuthType.GOOGLE: 'google';
    }
    return authProviderType;
  }
}
